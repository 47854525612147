<!-- 同班学员 -->
<template>
    <div class="Classmate">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">学员</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <div class="" style="margin-left: 21px;">
            <button class="btn" type="button" v-for="(item,index) in tabBtn" :key="index" @click="toTab(index)"
                :class="tab==index?'active':''">
                {{item}}
            </button>
        </div>
        <!-- <el-divider></el-divider> -->
        <!-- 收款表 -->
        <div class="" v-if="tab==0" :key="0">
            <el-table :data="CollectionData" border stripe style="width:1041px;flex: 1;" height="660" >
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column prop="" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" label="状态" align="center" width="100">
                </el-table-column>
                <el-table-column prop="" label="回款日期" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="培训费" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="业务经办" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="业务类型" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="摘要" align="center" width="300">
                </el-table-column>
            </el-table>
        </div>
        <!-- 同班学员 -->
        <div class="" v-if="tab==1" :key="1">
            <el-table :data="classmateData" border stripe style="width:97.9%;flex: 1;" height="660" >
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column prop="" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" label="学生号" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="学生姓名" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="报名日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="交费日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="退学日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="学籍类" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="报名上课次数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="学费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="退费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="经办人员" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="说明" align="center" width="300">
                </el-table-column>
            </el-table>
        </div>
        <!-- 学员历史培训 -->
        <div class="" v-if="tab==2" :key="2">
            <el-table :data="historyData" border stripe style="width:1271px;flex: 1;" height="660" >
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column prop="" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" label="学生姓名" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="提供机构" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="考试科目" align="center" width="200">
                </el-table-column>
                <el-table-column prop="" label="等级" align="center" width="100">
                </el-table-column>
                <el-table-column prop="" label="考试备注" align="center" width="200">
                </el-table-column>
                <el-table-column prop="" label="成绩" align="center" width="100">
                </el-table-column>
                <el-table-column prop="" label="成绩等级" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="考试日期" align="center" width="150">
                </el-table-column>
            </el-table>
        </div>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange"
         @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total" v-if="tab!==0">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                tabBtn:["收款表","同班学员","学员历史培训"],
                tab:0,//代表下标
                CollectionData:[],//收款表数据
                classmateData:[],//同班学员数据
                historyData:[],//学员历史培训数据
                currentPage: 1,
                total: 0,
            }
        },
        created() {
            
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            toTab(index) {//赋值下标
                this.tab = index
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            },
        }
    }
</script>

<style scoped="scoped">
    .Classmate{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* tab按钮 */
    .btn {
        display: inline-block;
        border: 1px solid #999999;
        background-color: #FFFFFF;
        padding: 5px 15px;
        /* margin: 20px 0px; */
        margin-right: 20px;
        font-size: 14px;
        border-radius: 3px;
        cursor: pointer;
    }
    
    /* 选中样式 */
    .active {
        background-color: #29726C;
        color: #FFFFFF;
    }
    
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 35px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
